import { FC, useEffect, useState } from "react";
import { CarDataType } from "data/types";
import TabFilters from "./TabFilters";
import FleetCard from "components/FleetCard/FleetCard";
import { useNavigate } from "react-router-dom";
import { useData } from "data/data-provider";
import { CarListingModal } from "models/vehicleModal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Heading2 from "components/Heading/Heading2";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CarDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
}) => {
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const navigate = useNavigate();
  const { ourVehicles, setSelectedCar } = useData();
  const [visibleCarsCount, setVisibleCarsCount] = useState<number>(8); // Track how many cars are visible
  const [expanded, setExpanded] = useState<boolean>(false); // Track if all cars are visible

  // Use useState for maxPrice and set it later
  const [maxPrice, setMaxPrice] = useState(0);
  const [filteredFleet, setFilteredFleet] = useState<CarListingModal[]>(
    ourVehicles || []
  );
  const [rangePrices, setRangePrices] = useState([0, maxPrice]);

  // Calculate maxPrice when ourVehicles is updated
  useEffect(() => {
    if (ourVehicles && ourVehicles.length > 0) {
      const calculatedMaxPrice = ourVehicles.reduce(
        (acc, current) => Math.max(acc, current.price),
        0
      );
      setMaxPrice(calculatedMaxPrice);
    }
  }, [ourVehicles]);

  // Set initial rangePrices based on maxPrice
  useEffect(() => {
    setVisibleCarsCount(8); // Reset visible cars count
    if (maxPrice > 0) {
      setRangePrices([0, maxPrice]);
    }
  }, [maxPrice]);

  // Filter vehicles by selected types
  useEffect(() => {
    setVisibleCarsCount(8); // Reset visible cars count
    if (ourVehicles) {
      if (
        selectedTypes.length === 0 ||
        Array.from(new Set(ourVehicles.map((car) => car?.fleet_class))).filter(
          (item): item is any => item !== undefined
        ).length === selectedTypes.length
      ) {
        setFilteredFleet(ourVehicles);
      } else {
        setFilteredFleet(
          ourVehicles.filter((item: CarListingModal) =>
            selectedTypes.includes(item?.fleet_class?.name ?? "")
          )
        );
      }
    }
  }, [selectedTypes, ourVehicles]);

  // Filter vehicles by price range
  useEffect(() => {
    setVisibleCarsCount(8); // Reset visible cars count
    if (ourVehicles) {
      setFilteredFleet(
        ourVehicles.filter(
          (item) => item.price >= rangePrices[0] && item.price <= rangePrices[1]
        )
      );
    }
  }, [rangePrices, ourVehicles]);

  // Determine how many cars to show based on visibleCarsCount
  const carsToDisplay = filteredFleet?.slice(0, visibleCarsCount);

  const handleShowMore = () => {
    // Increment the visible cars count by 8 each time
    setVisibleCarsCount((prevCount) => prevCount + 8);
    if (filteredFleet && visibleCarsCount + 8 >= filteredFleet.length) {
      setExpanded(true); // Set expanded to true if all cars are visible
    }
  };

  const handleShowLess = () => {
    // Collapse back to the first 8 cars
    setVisibleCarsCount(8);
    setExpanded(false); // Collapse the list
  };

  const renderCard = (car: CarListingModal) => {
    return (
      <FleetCard
        key={car.id}
        data={car}
        simpleImg={true}
        onClick={() => {
          navigate("/car-listing-detail");
          setSelectedCar(car);
        }}
      />
    );
  };
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
      style={{ minHeight: "80vh" }}
    >
    <div className="flex justify-between">
    <Heading2 heading={"Our Cars"} subHeading=" " />
        <div className="mb-8 lg:mb-0 flex items-center">
          {ourVehicles && (
            <TabFilters
              types={[
                ...Array.from(
                  new Set(
                    ourVehicles.map((car) => car?.fleet_class?.name ?? "")
                  )
                ).filter(
                  (item): item is string => item !== undefined && item !== ""
                ),
              ]}
              selectedTypes={selectedTypes}
              setSelectedTypes={setSelectedTypes}
              rangePrices={rangePrices}
              setRangePrices={setRangePrices}
              maxPrice={maxPrice}
            />
          )}
        </div>
      </div>
      <div className="nc-SectionGridFeaturePlaces relative">
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
        >
          {carsToDisplay?.map((car) => renderCard(car))}
        </div>
        {/* Show "Show me more" button if not all cars are shown */}
        {!expanded && ourVehicles?.length > visibleCarsCount && (
          <div className="flex mt-16 justify-center items-center">
            <ButtonPrimary className="rounded-md" onClick={handleShowMore}>
              Show me more
            </ButtonPrimary>
          </div>
        )}

        {/* Show "Show less" button if the list is expanded */}
        {expanded && (
          <div className="flex mt-16 justify-center items-center">
            <ButtonPrimary className="rounded-md" onClick={handleShowLess}>
              Show me less
            </ButtonPrimary>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
